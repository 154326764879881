import PropTypes from 'prop-types'
import React from 'react'

const Theme = props => (
    <div id="theme" style={props.timeout ? { display: 'none' } : {}}>
   theme
                        <div className="livefrontier">Live / Frontier</div>
        <div className="theme-text">今年のテーマは「Live/Frontier」<br />
新作・過去作問わず、人類の生き方や楽しみ方をアップデートするVRワールドを幅広く募集します！</div>
            <button　id="button-black" onClick={() => { props.onOpenArticle('theme-about') }}>テーマの詳細を知る</button>
        </div>
)

Theme.propTypes = {
    onOpenArticle: PropTypes.func,
    timeout:PropTypes.bool,
}

export default Theme
