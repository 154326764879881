import React from 'react';
import Kaigyo from '../components/Kaigyo'

class Modal extends React.Component {
    constructor(props) {
        super(props);
        this.state = { isModalOpen: false };
        this.modalRef = React.createRef()
        this.setWrapperRef = this.setWrapperRef.bind(this);
        this.handleClickClose = this.handleClickClose.bind(this)
        this.handleClickModal = this.handleClickModal.bind(this)
        this.handleClickOutside = this.handleClickOutside.bind(this)
    }

    handleClickModal() {
        this.setState({ isModalOpen: true });
    }

    handleClickClose() {
        this.setState({ isModalOpen: false });
    }

    componentDidMount() {
        document.addEventListener('click', this.handleClickOutside,true)
    }

    componentWillUnmount() {
        document.removeEventListener('click', this.handleClickOutside,true)
    }

    setWrapperRef(node) {
        this.wrapperRef = node;
    }


    handleClickOutside(event) {
        if (this.wrapperRef && !this.wrapperRef.contains(event.target)) {
            if (this.state.isModalOpen) {
                this.setState({
                    isModalOpen: !this.state.isModalOpen
                })
            }
        }
    }

    kaigyo(text) {
        var regex = '<br>';
        return text.split(regex).map((line) => {
            if (line.match(regex)) {
                return (<div className="modaltextbold">React.createElement('br')</div>);
            }
            else {
                return (<div className="modaltext">{line}</div>);
            }
        });
    }

    render() {
        let modal;
        if (this.state.isModalOpen) {
            modal = (
                <div className = "modal" >
                    <div ref={this.setWrapperRef} className="modal-inner">
                        
                            <div  className="modal-text">
                                <div className="sectiontitle">{this.props.title}</div>
                            <div className="modal-text-detail">{this.kaigyo(this.props.text)}</div>
                            </div>
                            <button className="closebutton"
                            onClick={() => { this.handleClickClose() }}
                                >
                                close</button>
                        
                    </div>
                </div>
                    )
        }

        return (
            <div className="modal-button">
                <button className="modalnobutton"
                    onClick={() => { this.handleClickModal() }}
                >
                    {this.props.buttontext}</button>
                {modal}
            </div>
        );
}
}

export default Modal;


