import React from 'react';

class Kaigyo extends React.Component {

    nl2br (text) {
    var regex = /(<br>)/g
    return text.split(regex).map((line) => {
        if (line.match(regex)) {
            return "<br>";
        }
        else {
            return line;
        }
    });
}
render() {
    return (
        <p>{this.nl2br(this.props.text)}</p>
    );
}
}


export default Kaigyo;