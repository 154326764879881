import PropTypes from 'prop-types'
import React from 'react'
import Logo from '../images/vraa_typo_vector.svg'

class About extends React.Component {
    render() {
        let close = (
            < div
        className = "close"
        onClick = {()=> {
            this.props.onCloseArticle()
                }}
            ></div>
        )

        return (
            <div
                ref={this.props.setWrapperRef}
                id="theme-title"
                style={this.props.timeout ? { display: 'flex' } : { display: 'none' }}
            >
                <article
                    id="theme-about"
                    className={`${this.props.article === 'theme-about' ? 'active' : ''} ${
                        this.props.articleTimeout ? 'timeout' : ''
                        }`}
                    style={{ display: 'none' }}
                >
                    <div className="abouttitle">募集テーマとは？</div>
                    <div className="abouttext">VRAAの募集テーマは、ワールドクリエイションのインスピレーションの種として、自由に解釈していただくために設定しています。
アワードは正解を求めるものではなく、ワクワクするものをみんなで見たいという気持ちで駆動しています。ぜひ気軽にご参加ください。
                        </div>
                    <div className="abouttitle">Live/Frontierとは？</div>
                    <div className="abouttext">
自由に解釈していただいて構いません。ただ、それぞれの言葉はいくつか解釈のヒントとなる意味を持っています。

                        <br/><br/>
                        “Live”は、リブ/ライブと２つの読み方ができます。<br />
                        この言葉は、「生きること・命(Life)」といった根源的な意味、「住む・暮らす」といった日常を想起させる意味、「活発な・活動的な」といった活動のエネルギー的盛り上がりを思わせる意味、「ナマ・生きている感・ライブであること」といったもののあり方を示す意味など、さまざまな捉え方が可能な言葉です。
                        <br />
                        <br />
                        ”Frontier”は、「辺境」あるいは「最先端・未踏領域」である場所です。<br />
                        2020年現在のVR空間はまだ世の中から言えば辺境ですが、そこで暮らす住人が日夜自らの手で「できること」をひとつずつ獲得していく極めてエキサイティングな場所です。生活に根ざしつつとても実験的であるという特徴的な場所を表す言葉として併記しています。
                        </div>
                    {close}
                </article>
            </div>

        )

    }
}

About.propTypes = {
    route: PropTypes.object,
    article: PropTypes.string,
    articleTimeout2: PropTypes.bool,
    onCloseArticle: PropTypes.func,
    timeout2: PropTypes.bool,
    setWrapperRef2:PropTypes.func.isRequired,
}

export default About