import PropTypes from 'prop-types'
import React from 'react'
import Modal from '../components/Modal'

class Profile extends React.Component {

    render() {
        return (
            <div id="profile-header">
                <span className="image main">
                    <img src={this.props.image} alt="" />
                </span>
                <p className="profile-name">{this.props.name}</p>
                <p className="profile-title1">{this.props.title1}</p>
                <p className="profile-title2">{this.props.title2}</p>
                <p className="profile-title3">{this.props.title3}</p>
            </div>
        );
    }
}

export default Profile
